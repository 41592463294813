/*@tailwind base;

@tailwind components;*/

/*@tailwind utilities;*/

html,
body {
  font-size: 16px !important;
}

body {
  background: #fff;
}